.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.indexContainer{
  padding: 20px;
}

.paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 10px;
}


.SchemaGroup{
  margin: 10px;
}

.Entity{
  border: 1px solid black;
  margin: 10px;
  background-color: white;
}


/*root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },*/
.table {
  minWidth: 650px;
}

 /* The only way to force the legend NOT to display for vasturiano/timelines-chart */
 .timelines-chart .legendG{
  display: none;
}
.timelines-chart {
  color: slategray;
}